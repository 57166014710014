import { ReduxWrapper } from '@/components/common/ReduxWrapper';
import useIsMobile from '@/hooks/useMediaQuery';
import store from '@/store';
import dynamic from 'next/dynamic';
import { ReactNode } from 'react';
import { Provider } from 'react-redux';
const FooterMobile = dynamic(
  () => import('./components/mobile/footer-mobile'),
  { ssr: false }
);

import WhatsappCall from '@/components/home/whatsapp-call';
import Analytics from '@/utils/anylitics';
import { useRouter } from 'next/router';
import DiscountStrip from './components/DiscountStrip';
import Footer from './components/Footer';
import Header from './components/Header';
import LoginModal from './components/login-modal';
import HeaderMobile from './components/mobile/HeaderMobile';
const FooterTitleMobile = dynamic(
  () => import('./components/mobile/footer-title-link-mobile'),
  {
    ssr: false,
  }
);

type Props = {
  children: ReactNode & {
    props?: any;
  };
  data?: any;
  isMobile?: boolean;
};

const Layout = ({ children, data = {} }: Props) => {
  const { infoData = {}, error = null } = data || {};
  const clientMobile = useIsMobile();
  const isMobile = data?.isMobile ? data?.isMobile : clientMobile;
  const router = useRouter();
  const isTrackOrderPage = [
    '/track-order-by-email',
    '/help-center/tickets',
  ].includes(router.pathname);
  return (
    <Provider store={store}>
      <ReduxWrapper>
        <Analytics isMobile={isMobile} />
        <LoginModal isMobile={isMobile} />
        {/* <ChatToggle isMobile={isMobile} /> */}
        <div className="common-wrapper">
          {isMobile ? (
            <>
              <HeaderMobile />
              <WhatsappCall />
            </>
          ) : (
            <>
              <DiscountStrip />
              <Header />
            </>
          )}

          <main className={isTrackOrderPage ? 'track-order-page' : ''}>
            {children}
          </main>

          {isMobile ? (
            <>
              <FooterTitleMobile
                informationData={infoData?.data}
                error={error}
              />
              <FooterMobile />
            </>
          ) : (
            <Footer />
          )}
        </div>
      </ReduxWrapper>
    </Provider>
  );
};

export default Layout;
