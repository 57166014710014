'use client';
import { useEffect } from 'react';
// const ChatToggle = dynamic(() => import('@/components/common/chat-toggle'), {
//   ssr: false,
// });
declare global {
  interface Window {
    fbq?: (...args: any[]) => void;
    gtag?: (...args: any[]) => void;
    dataLayer?: any[];
    clarity?: (...args: any[]) => void & { q?: any[] };
    smartech?: (...args: any[]) => void;
  }
}

function initializeGoogleAnalytics(): void {
  console.log('initializeGoogleAnalytics initialized'); //initialized
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any[]): void {
    window.dataLayer?.push(args);
  }
  gtag('js', new Date());
  gtag('config', 'G-7CJTNFP15G'); //check
  gtag('config', 'AW-11234199219'); //check

  (function (w: any, d: Document, s: string, l: string, i: string) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s) as HTMLScriptElement;
    const dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f?.parentNode?.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-WKJ6WBR');
}

function initializeMicrosoftClarityTracking(): void {
  if (window.clarity) return;
  (function (
    c: any,
    l: Document,
    a: string,
    r: string,
    i: string,
    t?: HTMLScriptElement,
    y?: Node
  ) {
    c[a] =
      c[a] ||
      function (...args: any[]) {
        (c[a].q = c[a].q || []).push(args);
      };
    t = l.createElement(r) as HTMLScriptElement;
    t.async = true;
    t.src = 'https://www.clarity.ms/tag/' + i;
    y = l.getElementsByTagName(r)[0];
    y?.parentNode?.insertBefore(t, y);
  })(window, document, 'clarity', 'script', 'frguloz9k4'); //Live: frguloz9k4 -- qqlcbdwfxd
}

function initializeSmartech(): void {
  if (
    document.querySelector(
      'script[src="//cdnt.netcoresmartech.com/smartechclient.js"]'
    )
  ) {
    return;
  }

  const script = document.createElement('script');
  script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    if (window.smartech) {
      window.smartech(
        'create',
        'ADGMOT35CHFLVDHBJNIG50K96BHHHBV673F604UD0TG5E9PHETLG'
      );
      window.smartech('register', 'fe57a03de5f7d89fe2377cceb58675f7');
    }
  };
}
function initializeFacebook(): void {
  if (window.fbq) return;
  (function (
    f: any,
    b: Document,
    e: string,
    v: string,
    n?: any,
    t?: HTMLScriptElement,
    s?: Node
  ) {
    n = f.fbq = function (...args: any[]) {
      if (n.callMethod) {
        n.callMethod(...args);
      } else {
        n.queue.push(args);
      }
    };

    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = true;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e) as HTMLScriptElement;
    t.async = true;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s?.parentNode?.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );

  window.fbq?.('init', '1139414164506626');
  window.fbq?.('track', 'PageView');
}

function initializeGoogleAds(): void {
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any[]): void {
    window.dataLayer?.push(args);
  }
  gtag('js', new Date());
  gtag('config', 'AW-11234199219');
}

function initializeUnbxd(): void {
  const script = document.createElement('script');
  script.src =
    'https://libraries.unbxdapi.com/sdk-clients/ss-unbxd-aapac-woodenstreet-dev62311737973712/ua-staging/ua.js';
  script.type = 'text/javascript';
  script.defer = true;
  document.head.appendChild(script);
}
/* eslint-disable @typescript-eslint/no-unused-vars */

const Analytics = ({
  isMobile,
}: {
  isMobile?: boolean;
}): JSX.Element | null => {
  // const [showChatToggle, setShowChatToggle] = useState(false);
  useEffect(() => {
    const handleInteraction = (): void => {
      initializeGoogleAnalytics();
      initializeMicrosoftClarityTracking();
      initializeSmartech();
      initializeFacebook();
      initializeGoogleAds();
      initializeUnbxd();
      // setShowChatToggle(true);
      cleanupListeners();
    };

    const cleanupListeners = (): void => {
      document.removeEventListener('mousemove', handleInteraction);
      document.removeEventListener('touchstart', handleInteraction);
    };

    if (process.env.NEXT_PUBLIC_APP_MODE === 'production') {
      document.addEventListener('mousemove', handleInteraction);
      document.addEventListener('touchstart', handleInteraction);
    }

    return cleanupListeners;
  }, []);

  return (
    <>
      <meta
        name="facebook-domain-verification"
        content="7ub26r4phmrftsumvuj9p039nbbzu7"
      />
      <link rel="dns-prefetch" href="https://tagmanager.google.com/" />
      <link rel="dns-prefetch" href="https://www.google-analytics.com" />
      <link rel="dns-prefetch" href="https://connect.facebook.net" />
      <meta name="p:domain_verify" content="9ee26ce0d5b05b03519b5257bb22142b" />
      <meta
        name="google-signin-client_id"
        content="317290152098-bmds3vnmcdhc2hbab6t2peebbanmq5rt.apps.googleusercontent.com"
      />
      <noscript key="gtm-noscript">
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-WKJ6WBR"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      {/* {showChatToggle && <ChatToggle isMobile={isMobile} />}{' '} */}
    </>
  );
};

export default Analytics;
